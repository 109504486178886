import { useState, useEffect } from 'react';

export function usePersistedState(key: string, defaultValue: any) {
  const currentValue = localStorage.getItem(key);
  const [state, setState] = useState(
    () => currentValue ? JSON.parse(currentValue) : defaultValue
  );
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
};
