import { createMuiTheme, Theme } from '@material-ui/core/styles';

// define light theme colors
export const lightTheme: Theme = createMuiTheme({
  spacing: 8,
  typography: {
    fontFamily: [
      'Sintony',
      'sans-serif'
    ].join(','),
  },
  palette: {
    type: "light",
    primary: {
      main: '#e66600',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
});

// define dark theme colors
export const darkTheme: Theme = createMuiTheme({
  spacing: 8,
  typography: {
    fontFamily: [
      'Sintony',
      'sans-serif'
    ].join(','),
  },
  palette: {
    type: "light",
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#F2F2F2',
    },
  },
});