import React, { FC, ReactElement, useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import crypto from 'crypto';
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import OAuth2Login from '../modules/oauth';
import { usePersistedState } from '../utils/usePersistedState';
import { fetchCommanderProfile, fetchAccessToken } from '../api';
import { accessTokenIsValid } from '../utils/accessTokenUtils';

// components
import PageTitle from "../components/PageTitle";

// constants
import { APP_TITLE, PAGE_TITLE_HOME } from "../utils/constants";


function base64URLEncode(str: Buffer) {
  return str.toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
}
var verifier = base64URLEncode(crypto.randomBytes(32));

var state = base64URLEncode(crypto.randomBytes(8));

function sha256(buffer: string) {
  return crypto.createHash('sha256').update(buffer).digest();
}
var challenge = base64URLEncode(sha256(verifier));
const authCodeUrl = 'https://auth.frontierstore.net/auth';


// define css-in-js
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  })
);

const formatToCurrency = (amount: number) => {
  return "ED$ " + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

const Home: FC<{}> = (): ReactElement => {
  const [accessToken, setAccessToken] = usePersistedState('accessToken', null);
  const [error, setError] = useState<Error>();
  const [commanderProfile, setCommanderProfile] = usePersistedState('commanderProfile', null);
  const [fetching, setFetching] = usePersistedState('fetching', false);

  const initializeCommanderProfile = useCallback(async (accessToken) => {
    setFetching(true);
    const commanderProfile = await fetchCommanderProfile(accessToken);
    setFetching(false);
    setCommanderProfile(commanderProfile);
    
  }, [setCommanderProfile, setFetching]);

  const onFrontierAuthSuccess = async (data: any) => {
    setFetching(true);
    const tokenResponse = await fetchAccessToken(data.code, verifier);
    setFetching(false);
    // If response valid
    console.log(tokenResponse);
    setAccessToken(tokenResponse);
  };

  useEffect(() => {
    if (accessToken && accessTokenIsValid(accessToken)) {
      initializeCommanderProfile(accessToken.access_token);
    }
  }, [initializeCommanderProfile, accessToken]);

  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLE_HOME} | {APP_TITLE}
        </title>
      </Helmet>
      <div className={classes.root}>
        <Grid container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={10}>
              <PageTitle title={PAGE_TITLE_HOME} />
              <Box mb={2} />
            </Grid>
            <Grid container item justify="flex-end" xs={2}>
            { fetching && <CircularProgress size={25} color="primary" /> }
            </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {
              error && <p>{error.message}</p>
            }
            {/* If accessToken is null or if its invalid */}
            { ((accessToken === null) || (accessToken && !accessTokenIsValid(accessToken))) &&
                <OAuth2Login
                authorizationUrl={authCodeUrl}
                clientId={process.env.REACT_APP_ED_CLIENT_ID}
                redirectUri={process.env.REACT_APP_CLIENT_URL}
                audience="frontier"
                codeChallenge={challenge}
                codeChallengeMethod="S256"
                scope="auth capi"
                state={state}
                responseType="code"
                buttonText="Login Commander"
                onSuccess={onFrontierAuthSuccess}
                onFailure={setError}
              />
            }
            { commanderProfile && 
              <Card className={classes.root}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Welcome CMDR,
                  </Typography>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {commanderProfile.commander.name}
                  </Typography>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Current Credits
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {formatToCurrency(commanderProfile.commander.credits)}
                  </Typography>
                </CardContent>
              </Card>
            }
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Home;
