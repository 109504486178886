import moment from 'moment';

export const fetchCommanderProfile = async (token: string) => {
  const res = await fetch(`${process.env.REACT_APP_CLIENT_URL}/.netlify/functions/get-profile?token=${token}`);
  const data = await res.json();
  return data;
};

export const fetchAccessToken = async (code: string, verifier: string) => {
  const res = await fetch(`${process.env.REACT_APP_CLIENT_URL}/.netlify/functions/get-auth-token?code=${code}&verifier=${verifier}&stage=${process.env.REACT_APP_STAGE_FOR_FUNCTIONS}`);
  const data = await res.json();
  data['expires_at'] = moment().add(data.expires_in, 'seconds').format();
  return data;
};