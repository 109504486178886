// APP TEXT
export const APP_TITLE = "ED Companion";
export const FOOTER_TEXT = `${new Date().getFullYear()} Built with ♡ by CMDR MaverickAkaMav`;
// PAGES TITLE
export const PAGE_TITLE_HOME = "Home";
export const PAGE_TITLE_DASHBOARD = "Dashboard";
export const PAGE_TITLE_SETTINGS = "Settings";
// UI CONSTANTS
export const FOOTER_HEIGHT = 30;
export const HEADER_HEIGHT = 60;
export const DRAWER_WIDTH = 250;